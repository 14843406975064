import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import Card from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { isInitial } from "@/__main__/constants.mjs";
import type { ValorantCoachingBenchmark } from "@/data-models/valorant-coaching-benchmark-data.mjs";
import { onComparisonTierChange } from "@/game-val/actions.mjs";
import { COACHING_CATEGORIES } from "@/game-val/coaching-categories.mjs";
import AbilitySummaryCard from "@/game-val/components/AbilitySummaryCard.jsx";
import {
  bgImg,
  performanceWrapper,
  RankIcon,
} from "@/game-val/components/MatchPerformance.style.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import type {
  PlayerData,
  PopulatedCoachingStat,
} from "@/game-val/constants/coaching-constants.mjs";
import { getCurrentStatsObj } from "@/game-val/postmatch-helpers.mjs";
import {
  getAgentImage,
  getPlayerMatchStats,
  getValorantRankImage,
} from "@/game-val/utils.mjs";
import {
  calculateCategory,
  isCoachingEnabledForQueue,
  performanceColor,
  scoreLabel,
} from "@/game-val/utils/coaching.mjs";
import { RankComparison } from "@/shared/Match.style.jsx";
import { PerfGrid } from "@/shared/Performance.style.jsx";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import type { StatRow } from "@/shared/PerformanceInputStats.jsx";
import PerformanceInputStats from "@/shared/PerformanceInputStats.jsx";
import debounce from "@/util/debounce.mjs";
import { devLog } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import keyInObject from "@/util/key-in-object.mjs";
import titleCase from "@/util/title-case.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

interface MatchPerformanceProps {
  abilityKillData: Record<
    "ability1Casts" | "ability2Casts" | "grenadeCasts" | "ultimateCasts",
    { kills: number; casts: number }
  >;
  agent: string;
  matchId: string;
  nameTag: string;
  queue: string;
  initialTier: string;
  baseHeight?: string | number;
}

const tierSelectorCss = () => css`
  margin-left: var(--sp-2);
`;

function PerformanceComparisonIcon({ tier }: { tier: string }) {
  return (
    <RankIcon>
      <img
        alt={`${tier} rank icon`}
        src={getValorantRankImage({ tier, rank: 0, size: "small" })}
      />
    </RankIcon>
  );
}

function RankComparisonHeader({
  tier,
  setTier,
}: {
  tier: string;
  setTier: (value: string) => void;
}) {
  const { t } = useTranslation();

  const { val } = useSnapshot(readState);
  const ranks = val.content?.ranks;
  const benchmarkData = val.coachingBenchmark;

  const tierOptions = useMemo(
    () =>
      Object.keys(benchmarkData)
        .map((tier) => ({
          value: tier,
          text: [`val:tier.${tier}`, titleCase(tier)] satisfies Translation,
          icon: <PerformanceComparisonIcon tier={tier} />,
        }))
        .sort((a, b) => {
          const rankA = ranks?.find((r) => r.tier === a.value)?.position ?? -1;
          const rankB = ranks?.find((r) => r.tier === b.value)?.position ?? -1;
          return rankA - rankB;
        }),
    [benchmarkData, ranks],
  );

  return (
    <RankComparison>
      <span className="vs type-form--button">{t("common:vs", "vs")}</span>
      <Select
        options={tierOptions}
        selected={tier}
        onChange={setTier}
        containerClassName={tierSelectorCss()}
      />
    </RankComparison>
  );
}

export function MatchPerformanceLoading({
  baseHeight = "22.5rem",
}: {
  baseHeight?: string | number;
}) {
  return (
    <PerfGrid className={performanceWrapper}>
      <Card style={{ height: baseHeight }} loading />
      <Card style={{ height: baseHeight }} loading />
      <Card style={{ height: baseHeight }} loading />
    </PerfGrid>
  );
}

const DEFAULT_COMPARISON_TIER =
  "bronze" as const satisfies keyof ValorantCoachingBenchmark;

function MatchPerformance({
  abilityKillData,
  agent,
  baseHeight = "22.5rem",
  matchId,
  nameTag,
  queue,
}: MatchPerformanceProps) {
  const {
    val,
    settings: {
      valorant: { comparisonTier },
    },
  } = useSnapshot(readState);

  const matchInfo = val.match[matchId];
  const profile = val.profiles[nameTag];
  const coachingData = val.coachingData[matchId];
  const benchmarkData = val.coachingBenchmark;

  const playerStats =
    matchInfo && profile ? getPlayerMatchStats(matchInfo, profile) : null;
  const matchStats = playerStats ? getCurrentStatsObj(playerStats) : null;
  const puuid = profile && !(profile instanceof Error) ? profile.puuid : null;
  const playerId =
    profile && !(profile instanceof Error)
      ? profile.valorantProfile.internalUuid
      : null;

  const extraStats = coachingData?.extraStats ?? null;
  const rawMatch = coachingData?.rawMatch ?? null;

  const isCoachingEnabled = isCoachingEnabledForQueue(queue);
  const showPerformance = rawMatch && isCoachingEnabled;

  const userInteraction = useInteractionEvent("val-coaching");

  const [onHover, cancelHover] = useMemo(() => {
    let cancelFunc = () => {};
    const firedFor = new Set<string>();

    const hoverHandler = debounce(
      (_evt: React.MouseEvent<HTMLDivElement>, row: StatRow) => {
        const label = Array.isArray(row.label) ? row.label[1] : row.label;
        if (firedFor.has(label)) return;

        firedFor.add(label);
        userInteraction(
          "performance-stat",
          {
            stat: label,
          },
          "hover",
        );
      },
      300,
    );

    return [
      (evt: React.MouseEvent<HTMLDivElement>, row: StatRow) => {
        cancelFunc?.();
        cancelFunc = hoverHandler(evt, row);
      },
      () => cancelFunc?.(),
    ];
  }, [userInteraction]);

  const mapToInputStats = useCallback((stat: PopulatedCoachingStat) => {
    const { performance, label, description } = stat;
    const valueDisplay = stat?.displayScore
      ? performance.score
      : performance.val;

    return {
      label,
      valueDisplay: valueDisplay.toLocaleString(
        getLocale(),
        stat.formatOptions,
      ),
      fill: Math.max(performance?.score ?? 0, 0.01), // Give the bar some fill even if score is zero
      tooltip: description,
      value: performance.val,
      format: stat.formatOptions,
      min: performance.min,
      max: performance.max,
    };
  }, []);

  const benchmark = keyInObject(benchmarkData, comparisonTier)
    ? benchmarkData[comparisonTier]
    : benchmarkData[DEFAULT_COMPARISON_TIER];

  const { combat: combatCategory, economy: economyCategory } = useMemo(() => {
    // Shouldn't show performance, or missing vital data to calculate stats.
    if (!showPerformance || !playerId || !puuid || !matchStats || !playerStats)
      return { combat: null, economy: null };

    const playerData: PlayerData = {
      meta: {
        tier: comparisonTier,
        matchId,
        playerId,
        puuid,
        queue,
      },
      matchStats,
      playerStats,
      extraStats,
      rawMatch,
    };

    const combat = calculateCategory({
      category: COACHING_CATEGORIES.combat,
      playerData,
      benchmarkData: benchmark,
    });

    const economy = calculateCategory({
      category: COACHING_CATEGORIES.economy,
      playerData,
      benchmarkData: benchmark,
    });

    return { combat, economy };
  }, [
    benchmark,
    matchId,
    matchStats,
    playerId,
    playerStats,
    puuid,
    queue,
    showPerformance,
    comparisonTier,
    rawMatch,
    extraStats,
  ]);

  devLog("Attempting to render MatchPerformance", {
    isCoachingEnabled,
    benchmark,
    playerStats,
    matchStats,
    coachingData,
    combatCategory,
    economyCategory,
  });

  if (!queue || coachingData?.[isInitial]) {
    return <MatchPerformanceLoading baseHeight={baseHeight} />;
  }

  if (
    !isCoachingEnabled ||
    !benchmark ||
    playerStats instanceof Error ||
    matchStats instanceof Error ||
    profile instanceof Error ||
    !coachingData ||
    (coachingData && !(combatCategory && economyCategory))
  ) {
    return null;
  }

  return (
    <>
      <RankComparisonHeader
        tier={comparisonTier ?? DEFAULT_COMPARISON_TIER}
        setTier={onComparisonTierChange}
      />
      <PerfGrid
        style={{
          "--agent-color": `${
            keyInObject(AGENT_COLORS, agent)
              ? `hsl(${AGENT_COLORS[agent]})`
              : null
          }`,
        }}
        className={performanceWrapper}
      >
        {combatCategory && (
          <PerformanceCard
            title={combatCategory.title}
            score={combatCategory.score}
            bgImg={{
              src: getAgentImage(agent, "cutout"),
              className: bgImg,
            }}
            customPerformanceColor={performanceColor}
            customScoreLabeler={scoreLabel}
            subContent={
              <PerformanceInputStats
                rows={combatCategory.stats.map(mapToInputStats)}
                onMouseOver={onHover}
                onMouseOut={cancelHover}
                customPerformanceColor={performanceColor}
              />
            }
          />
        )}
        {economyCategory && (
          <PerformanceCard
            title={economyCategory.title}
            score={economyCategory.score}
            bgImg={{
              src: getAgentImage(agent, "cutout"),
              className: bgImg,
            }}
            customPerformanceColor={performanceColor}
            customScoreLabeler={scoreLabel}
            subContent={
              <PerformanceInputStats
                rows={economyCategory.stats.map(mapToInputStats)}
                onMouseOver={onHover}
                onMouseOut={cancelHover}
                customPerformanceColor={performanceColor}
              />
            }
          />
        )}
        <AbilitySummaryCard
          abilityKillData={abilityKillData}
          agent={agent}
          baseHeight={baseHeight}
          matchId={matchId}
        />
      </PerfGrid>
    </>
  );
}

export default MatchPerformance;
